import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  article: {
    maxWidth: theme.main.sizes.articleMaxWidth,
    minHeight: "100%",

    margin: "0 auto",
    padding: "1.5rem 30px",
    display: "flex",
    flexDirection: "column",

    "& strong, & b": {
      letterSpacing: "-.02em",
    },

    "& a": {
      fontWeight: "bold",
      letterSpacing: "-.02em",
      textShadow: `
           2px  2px ${theme.main.colors.background},
          -2px  2px ${theme.main.colors.background},
          -2px -2px ${theme.main.colors.background},
          -2px  2px ${theme.main.colors.background},
          -2px  0   ${theme.main.colors.background},
           2px  0   ${theme.main.colors.background}
        `,
      display: "inline-block",
      textDecoration: "none",
      transition: "0.3s",

      "&:hover": {
        color: theme.base.colors.linkHover,
      },
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      paddingRight: "40px",
      paddingLeft: "40px",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      textAlign: "right",
      maxWidth: "50%",
      float: "right",
      padding: "4rem 116px 0 0", // 78 + 28 + 10
    },

    "@media all and (-ms-high-contrast:none)": {
      height: "100%",
    },
  },
});

const Article = ({ children, classes, style }) => (
  <article className={classes.article} style={style}>
    {children}
  </article>
);

Article.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Article);
