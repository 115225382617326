import React from "react";
import PropTypes from "prop-types";
import rehypeReact from "rehype-react";

import Layout from "../../components/Layout";
import Container from "../../components/Container";
import Background from "../../components/Background";
import Article from "../../components/Article";
import Content from "../../components/Article/Content";
import Header from "../../components/Article/Header";
import LinkButton from "./LinkButton";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "media-link": LinkButton,
  },
}).Compiler;

const MediaTemplate = ({ data, location }) => {
  const { page = {} } = data;
  const pageTitle = (page.frontmatter || {}).title;
  const bgPosition = page.frontmatter.bgPosition || "66% center";

  return (
    <Layout location={location}>
      <Container location={location}>
        <Background
          fluid={data.image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition={bgPosition}
        />

        <Article>
          <Header title={pageTitle} />
          <Content>{renderAst(page.htmlAst)}</Content>
        </Article>
      </Container>
    </Layout>
  );
};

MediaTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default MediaTemplate;
