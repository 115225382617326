import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  header: {
    position: "sticky",
  },

  title: {
    color: theme.main.colors.title,
    fontSize: `${theme.main.fonts.title.size}em`,
    letterSpacing: "-0.03em",
    margin: "0 0 .5em 0",
    fontWeight: "bold",

    "& strong": {
      fontWeight: 600,
      letterSpacing: "-.05em",
    },

    "& a": {
      borderBottom: "1px solid",
      color: "inherit",
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      fontSize: `${theme.main.fonts.title.sizeM}em`,
    },

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      fontSize: `${theme.main.fonts.title.sizeL}em`,
      letterSpacing: "-0.05em",
    },
  },
});

const Header = (props) => {
  // function formatTitle(title) {
  //   const capitalIndex = title.search(/[A-Z]/);

  //   return (
  //     <span>
  //       {title.substring(0, capitalIndex)}
  //       <strong>{title.substring(capitalIndex)}</strong>
  //     </span>
  //   );
  // }

  const { classes, title } = props;

  return (
    <header className={classes.header}>
      <h1 className={classes.title}>{title.toUpperCase()}</h1>
    </header>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);
