import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const styles = (theme) => ({
  wrap: {
    display: "flex",
    alignItems: "center",
    margin: "1.3em 0",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      flexDirection: "row",
    },
  },

  button: {
    width: "50px",
    height: "50px",
    minWidth: 0,
    borderRadius: "50%",
    border: `2px solid ${theme.base.colors.text} !important`,
    display: "inline-flex !important",
    color: `${theme.base.colors.text} !important`,
    flexShrink: 0,
    marginRight: "15px",

    [`@media (min-width: ${theme.mediaQueryTresholds.L}px)`]: {
      marginLeft: "15px",
    },
  },
});

const LinkButton = ({ children, href, classes }) => {
  return (
    <div className={classes.wrap}>
      <span>{children}</span>
      <Button
        variant="outlined"
        className={classes.button}
        href={href}
        target="_blank"
        rel="noopener"
      >
        <ArrowForwardIcon />
      </Button>
    </div>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(LinkButton);
